<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 435.2 435.2">
    <path
      :class="{ active: active }"
      class="support"
      d="M356.9 224.8c0-8.7-6.7-15.4-15.4-15.4s-15.4 6.7-15.4 15.4a108.6 108.6 0 01-217 0 15 15 0 00-15.4-15.4 15 15 0 00-15.4 15.4c0 71.1 53.3 131 124 138.7v41h-55.9c-8.7 0-15.3 6.6-15.3 15.3s6.6 15.4 15.3 15.4h142.4c8.7 0 15.3-6.7 15.3-15.4s-6.6-15.3-15.3-15.3H233v-41a139.2 139.2 0 00123.9-138.7z"
    />
    <path
      :class="{ active: active }"
      class="micro"
      d="M217.6 0a85.7 85.7 0 00-85.5 85.5v138.8a86.1 86.1 0 0085.5 86 85.7 85.7 0 0085.5-85.5V85.5A85.7 85.7 0 00217.6 0z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.micro,
.support {
  fill: #a3a9b4;
}
.micro.active,
.support.active {
  fill: #6ebeff;
}
</style>
