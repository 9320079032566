<template>
  <circular-count-down-timer
    :initial-value="45"
    @finish="$emit('finished')"
    :show-hour="false"
    :show-minute="false"
    :size="250"
    :second-label="''"
    :stroke-width="10"
    :seconds-stroke-color="'#00aeef'"
    ref="countdown"
    class="mb-1 mt-4 text-center w-100"
  ></circular-count-down-timer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
