<template>
  <b-alert show variant="info" class="mt-4 py-4 px-4">
    <h5>
      <b-icon icon="info-circle-fill" class="mr-2"></b-icon>
      {{ $t('messages.recordInscrutionsTitle') }}
    </h5>
    <ul class="mt-3 pl-4 display-5 info-recorder-list">
      <li>
        {{ $t('messages.recordInscrutions1') }}
      </li>
      <li>
        {{ $t('messages.recordInscrutions2') }}
      </li>
      <li>
        {{ $t('messages.recordInscrutions3') }}
      </li>
      <li>
        {{ $t('messages.recordInscrutions4') }}
      </li>
    </ul>
  </b-alert>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.info-recorder-list li {
  margin: 0.8rem 0;
}
</style>
